/* General Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Arial', sans-serif;
  background-color: #f1f1f1;
}

.app {
  max-width: 500px;
  margin: 50px auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.auth-section,
.send-message-form {
  padding: 20px;
}

h1 {
  color: #333;
  margin-bottom: 20px;
  font-size: 24px;
  text-align: center;
}

input[type="text"],
input[type="password"],
textarea,
input[type="datetime-local"],
input[type="file"],
select { /* Added select to style dropdown */
  width: 100%;
  padding: 12px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px; /* Increased font size for better readability */
}

textarea {
  height: 100px;
  resize: none;
}

button {
  padding: 12px 20px;
  border: none;
  background-color: #007BFF;
  color: white;
  font-size: 16px; /* Increased font size */
  cursor: pointer;
  border-radius: 4px;
  width: 100%;
  margin-bottom: 10px;
}

button:hover {
  background-color: #0056b3;
}

button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

img {
  display: block;
  margin: 10px auto;
  width: 200px; /* Adjust width for responsiveness */
  max-width: 100%; /* Ensure it scales down on smaller screens */
  border-radius: 8px;
}

.message-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Dropdown styling */
select {
  appearance: none; /* Remove default dropdown arrow */
  background-color: #fff; /* Background color */
  background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24"%3E%3Cpath fill="%23007BFF" d="M7 10l5 5 5-5z"/%3E%3C/svg%3E'); /* Custom arrow */
  background-repeat: no-repeat;
  background-position: right 10px center; /* Position arrow */
  background-size: 12px; /* Size of arrow */
}

@media (max-width: 768px) {
  .app {
    padding: 15px;
  }

  h1 {
    font-size: 20px; /* Smaller font size for headings on mobile */
  }

  input[type="text"],
  input[type="password"],
  textarea,
  button,
  select {
    font-size: 14px; /* Smaller font size for inputs and buttons on mobile */
    padding: 10px; /* Adjust padding for smaller screens */
  }
}

@media (min-width: 769px) {
  h1 {
    font-size: 24px; /* Ensure larger font size on desktop */
  }

  input[type="text"],
  input[type="password"],
  textarea,
  button,
  select {
    font-size: 16px; /* Default font size for inputs and buttons on desktop */
    padding: 12px; /* Default padding for desktop */
  }
}
